<template>
  <div class="add-note">
    <base-title-bar-scroll-page title="添加记录" ref="container" :showTitleBar="!isApp">
      <div style="">
        <div class="form-container">
          <!-- 记录时间 -->
          <div class="form-item">
            <div class="form-label">记录时间</div>
            <div class="form-input" @click="showTimePicker = true">
              <van-icon name="clock-o" class="input-icon" />
              {{ formData.recordDate || '请选择时间' }}
            </div>
          </div>

          <!-- 记录内容 -->
          <div class="form-item">
            <div class="form-label">记录内容</div>
            <van-field v-model="formData.content" type="textarea" rows="4" placeholder="请输入记录内容"
              class="content-input" />
          </div>

          <!-- 附件上传 -->
          <div class="form-item">
            <div class="form-label">附件</div>
            <van-uploader v-model="formData.attachments" multiple :max-count="9" :after-read="afterRead"
              accept="image/*,video/*">
              <div class="upload-trigger">
                <van-icon name="plus" size="24" color="#2c8be9" />
                <div class="upload-text">上传图片/视频</div>
              </div>
            </van-uploader>
          </div>
        </div>

        <!-- 提交按钮 -->
        <div class="submit-btn">
          <van-button type="primary" block round @click="handleSubmit">提交</van-button>
        </div>

        <!-- 上传loading -->
        <van-overlay :show="uploading" class="upload-overlay">
          <div class="upload-loading">
            <van-loading type="spinner" color="#2c8be9" size="36" />
            <div class="upload-progress">
              <div class="progress-text">正在上传：{{ currentUpload.fileName }}</div>
              <div class="progress-bar">
                <div class="progress-inner" :style="{ width: currentUpload.progress + '%' }"></div>
              </div>
              <div class="progress-value">{{ currentUpload.progress }}%</div>
            </div>
            <van-button class="cancel-btn" size="small" @click="cancelUpload">取消上传</van-button>
          </div>
        </van-overlay>

        <!-- 时间选择器弹窗 -->
        <van-popup v-model="showTimePicker" position="bottom" round>
          <van-datetime-picker v-model="currentDate" type="datetime" title="选择时间" :min-date="minDate"
            :max-date="maxDate" @confirm="onTimeConfirm" @cancel="showTimePicker = false" />
        </van-popup>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>

<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Field, Button, Uploader, Icon, Popup, DatetimePicker, Overlay, Loading } from 'vant'
import { formatDate } from '../../../../../utils/date'
import { uploadFile } from '../../../../../utils/AliOssUploadUtils'
import PatientNoteDao from '@/h5/doucan/network/PatientNoteDao'

export default {
  name: 'add_note',
  components: {
    BaseTitleBarScrollPage,
    'van-field': Field,
    'van-button': Button,
    'van-uploader': Uploader,
    'van-icon': Icon,
    'van-popup': Popup,
    'van-datetime-picker': DatetimePicker,
    'van-overlay': Overlay,
    'van-loading': Loading
  },
  data () {
    return {
      isApp: false,
      showTimePicker: false,
      currentDate: new Date(),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
      formData: {
        recordDate: formatDate(new Date(), 'YYYY-MM-DD HH:mm'),
        content: '',
        attachments: []
      },
      // 上传相关
      uploadDir: {
        image: `patient_notes/images/${formatDate(new Date(), 'YYYY-MM')}`,
        video: `patient_notes/videos/${formatDate(new Date(), 'YYYY-MM')}`,
        cover: `patient_notes/videos/${formatDate(new Date(), 'YYYY-MM')}/covers`
      },
      uploading: false,
      currentUpload: {
        index: -1,
        progress: 0,
        fileName: ''
      },
      isCancelled: false,
      currentUploadController: null
    }
  },
  mounted () {
    this.isApp = this.$route.query.app === 'true'
    this.hideLoading()
  },
  methods: {
    /**
     * 隐藏加载状态
     */
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    // 处理时间选择确认
    onTimeConfirm (value) {
      this.formData.recordDate = formatDate(value, 'YYYY-MM-DD HH:mm')
      this.showTimePicker = false
    },

    /**
     * 生成文件名
     * @param {string} originalName 原始文件名
     * @returns {string} 新文件名
     */
    generateFileName (originalName) {
      // 获取文件后缀
      const ext = originalName.split('.').pop()

      // 生成日期时间字符串
      const dateStr = formatDate(new Date(), 'YYYYMMDDHHmmss')

      // 生成8位随机数
      const randomStr = Math.random().toString().slice(2, 10)

      return `${dateStr}_${randomStr}.${ext}`
    },

    // 处理文件上传后的回调
    async afterRead (file) {
      try {
        this.isCancelled = false

        // 处理单个文件上传
        const processFile = async (fileItem, fileIndex) => {
          this.uploading = true
          this.currentUpload = {
            index: fileIndex,
            progress: 0,
            fileName: fileItem.file.name
          }

          // 判断文件类型
          const isVideo = fileItem.file.type.startsWith('video/')
          const dir = isVideo ? this.uploadDir.video : this.uploadDir.image

          // 生成新文件名
          const newFileName = this.generateFileName(fileItem.file.name)

          // 创建新的File对象
          const newFile = new File([fileItem.file], newFileName, {
            type: fileItem.file.type
          })

          // 上传文件
          let uploadedUrl = ''
          try {
            // 创建AbortController实例
            this.currentUploadController = new AbortController()

            uploadedUrl = await new Promise((resolve, reject) => {
              uploadFile(
                dir,
                newFile,
                (percent) => {
                  if (this.isCancelled) {
                    this.currentUploadController.abort()
                    reject(new Error('Upload cancelled'))
                    return
                  }
                  this.currentUpload.progress = percent
                },
                (fileUrl) => {
                  // 确保使用HTTPS URL
                  const httpsUrl = fileUrl.replace('http://', 'https://')
                  resolve(httpsUrl)
                },
                (error) => reject(error),
                this.currentUploadController.signal
              )
            })

            // 更新文件信息
            fileItem.status = 'done'
            fileItem.message = '上传成功'
            fileItem.type = isVideo ? 'video' : 'image'
            fileItem.url = uploadedUrl
          } catch (error) {
            if (error.message === 'Upload cancelled') {
              this.$toast('已取消上传')
              // 从attachments中移除当前文件
              const index = this.formData.attachments.indexOf(fileItem)
              if (index > -1) {
                this.formData.attachments.splice(index, 1)
              }
              return
            }
            this.$toast.fail('文件上传失败')
            // 从attachments中移除当前文件
            const index = this.formData.attachments.indexOf(fileItem)
            if (index > -1) {
              this.formData.attachments.splice(index, 1)
            }
          }
        }

        // 处理多文件上传
        if (Array.isArray(file)) {
          for (let i = 0; i < file.length; i++) {
            if (this.isCancelled) {
              // 移除剩余未上传的文件
              this.formData.attachments.splice(i)
              break
            }
            await processFile(file[i], i)
          }
        } else {
          await processFile(file, 0)
        }
      } catch (error) {
        console.error('文件处理失败:', error)
        this.$toast.fail('文件处理失败')
      } finally {
        this.uploading = false
        this.currentUpload = {
          index: -1,
          progress: 0,
          fileName: ''
        }
        this.currentUploadController = null
      }
    },

    /**
     * 获取视频封面
     * @param {File} file 视频文件
     * @param {string} videoUrl 视频URL
     * @param {string} videoFileName 视频文件名
     * @returns {Promise<string>} 封面URL
     */
    async getVideoThumbnail (file, videoUrl, videoFileName) {
      return new Promise((resolve, reject) => {
        try {
          // 创建视频元素
          const video = document.createElement('video')
          video.crossOrigin = 'anonymous'
          video.preload = 'metadata'

          // 设置视频源
          // 优先使用本地文件URL,因为跨域限制可能无法访问远程视频
          video.src = URL.createObjectURL(file)

          // 监听元数据加载完成
          video.onloadedmetadata = () => {
            // 跳转到第一帧
            video.currentTime = 0.1
          }

          // 监听时间更新
          video.onseeked = async () => {
            try {
              // 创建canvas
              const canvas = document.createElement('canvas')
              canvas.width = video.videoWidth
              canvas.height = video.videoHeight

              // 绘制视频帧
              const ctx = canvas.getContext('2d')
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

              // 转换为文件并上传
              const blob = await new Promise(resolve => {
                canvas.toBlob(resolve, 'image/jpeg', 0.95)
              })

              // 释放本地URL
              URL.revokeObjectURL(video.src)

              // 生成封面文件名
              const coverFileName = this.generateFileName(videoFileName.replace(/\.[^.]+$/, '') + '_cover.jpg')

              // 创建封面文件
              const coverFile = new File([blob], coverFileName, {
                type: 'image/jpeg'
              })

              // 上传封面
              const coverUrl = await new Promise((resolve, reject) => {
                uploadFile(
                  this.uploadDir.cover,
                  coverFile,
                  null,
                  (fileUrl) => {
                    const httpsUrl = fileUrl.replace('http://', 'https://')
                    resolve(httpsUrl)
                  },
                  (error) => reject(error)
                )
              })

              resolve(coverUrl)
            } catch (error) {
              reject(error)
            }
          }

          // 设置超时
          setTimeout(() => {
            URL.revokeObjectURL(video.src)
            reject(new Error('获取视频封面超时'))
          }, 10000)

          // 处理错误
          video.onerror = (e) => {
            URL.revokeObjectURL(video.src)
            reject(new Error(`视频加载失败: ${e.message}`))
          }
        } catch (error) {
          reject(error)
        }
      })
    },

    // 取消上传
    cancelUpload () {
      this.isCancelled = true
      if (this.currentUploadController) {
        this.currentUploadController.abort()
      }
    },

    // 处理表单提交
    async handleSubmit () {
      try {
        // 表单验证
        if (!this.formData.recordDate) {
          this.$toast('请选择记录时间')
          return
        }
        if (!this.formData.content) {
          this.$toast('请输入记录内容')
          return
        }

        // 如果还在上传中,等待上传完成
        if (this.uploading) {
          this.$toast('请等待文件上传完成')
          return
        }

        // 显示加载状态
        this.$toast.loading({
          message: '提交中...',
          forbidClick: true,
          duration: 0
        })

        // 获取附件URL列表
        const attachmentUrls = this.formData.attachments.map(file => file.url)
        // 构造请求参数
        const noteData = {
          patientUserId: this.$route.query.patient_user_id,
          doctorUserId: this.$route.query.doctor_user_id,
          // doctorName: this.doctorInfo?.name || '未知医生', // 从医生信息中获取
          // hospitalId: this.$store.state.hospitalId,
          recordDate: this.formData.recordDate,
          content: this.formData.content,
          attachmentUrls: attachmentUrls
        }
        // 调用创建笔记API
        const response = await PatientNoteDao.createNote(noteData)
          
        // 关闭加载提示
        this.$toast.clear()

        if (response.code === 200) {
          this.$toast.success('添加成功')
          // 返回上一页
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        } else {
          this.$toast.fail(response.message || '添加失败')
        }
      } catch (error) {
        console.error('提交笔记失败:', error)
        this.$toast.clear()
        this.$toast.fail('提交失败，请稍后重试')
      }
    }
  }
}
</script>

<style scoped>
.add-note {
  min-height: 100vh;
  background: #f5f7fa;
}

.form-container {
  margin: 16px;
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.form-item {
  margin-bottom: 24px;
  transition: all 0.3s ease;
}

.form-item:last-child {
  margin-bottom: 0;
}

.form-label {
  color: #2c8be9;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.form-input {
  background: #f8fafc;
  padding: 14px;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  border: 1px solid #e8eef4;
}

.form-input:hover {
  border-color: #2c8be9;
  box-shadow: 0 0 0 2px rgba(44, 139, 233, 0.1);
}

.input-icon {
  margin-right: 8px;
  color: #2c8be9;
}

.content-input {
  border-radius: 8px;
  transition: all 0.3s ease;
  padding: 0;
}

:deep(.van-field__control) {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e8eef4;
  background: #f8fafc;
}

:deep(.van-field__control:focus) {
  border-color: #2c8be9;
  box-shadow: 0 0 0 2px rgba(44, 139, 233, 0.1);
}

:deep(.van-uploader) {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

:deep(.van-uploader__preview) {
  width: 60px !important;
  height: 60px !important;
  margin: 0 4px 0 0 !important;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid #e8eef4;
}

:deep(.van-uploader__preview-image) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

:deep(.van-uploader__upload) {
  width: 60px !important;
  height: 60px !important;
  margin: 0 !important;
}

.upload-trigger {
  width: 60px;
  height: 60px;
  background: #f8fafc;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #e8eef4;
  transition: all 0.3s ease;
}

.upload-trigger:hover {
  border-color: #2c8be9;
  background: #f0f7ff;
}

.upload-text {
  margin-top: 4x;
  font-size: 8px;
  color: #666;
}

.submit-btn {
  margin: 32px 16px;
}

.upload-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-loading {
  background: rgba(255, 255, 255, 0.95);
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-progress {
  margin-top: 16px;
  width: 240px;
  text-align: center;
}

.progress-text {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
}

.progress-bar {
  height: 4px;
  background: #eee;
  border-radius: 2px;
  overflow: hidden;
  margin: 8px 0;
}

.progress-inner {
  height: 100%;
  background: linear-gradient(135deg, #2c8be9, #1565c0);
  transition: width 0.3s ease;
}

.progress-value {
  color: #2c8be9;
  font-size: 12px;
  font-weight: 500;
}

.cancel-btn {
  margin-top: 16px;
  width: 120px;
  height: 32px;
  border: 1px solid #2c8be9;
  color: #2c8be9;
  border-radius: 16px;
  font-size: 14px;
  background: transparent;
  transition: all 0.3s ease;
}

.cancel-btn:active {
  background: rgba(44, 139, 233, 0.1);
}

:deep(.van-button--primary) {
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  background: linear-gradient(135deg, #2c8be9, #1565c0);
  border: none;
  transition: all 0.3s ease;
}

:deep(.van-button--primary:active) {
  transform: scale(0.98);
}

:deep(.van-popup) {
  border-radius: 16px 16px 0 0;
}
</style>
