/**
 * 格式化日期
 * @param {Date|string|number} date 日期对象/时间戳/日期字符串
 * @param {string} format 格式化模板，如：YYYY-MM-DD HH:mm:ss
 * @returns {string} 格式化后的日期字符串
 */
export function formatDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) return ''

  // 如果是时间戳或日期字符串，转换为Date对象
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const map = {
    YYYY: year,
    MM: padZero(month),
    DD: padZero(day),
    HH: padZero(hour),
    mm: padZero(minute),
    ss: padZero(second)
  }

  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, matched => map[matched])
}

/**
 * 数字补零
 * @param {number} num 需要补零的数字
 * @returns {string} 补零后的字符串
 */
function padZero(num) {
  return String(num).padStart(2, '0')
}
